import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import {
    ChevronDown,
    ClipboardCopy,
    ThumbsUp,
    ThumbsDown,
    SendHorizontal,
    Image,
    FileText
} from "lucide-react";
import configData from "../configData.json";
import botImg from "../assets/images/bot.png";
import ResponseChatBot from '../assets/images/ResponseChatBot.jpg'
import autobahn, { log } from "autobahn";

const BOT_NAME = "HBot";
const PERSON_NAME = "User";


const TypewriterText = ({
    text,
    speed = 8000,
    maxWords = 3
}) => {
    const [displayedText, setDisplayedText] = useState('');
    const [isAnimating, setIsAnimating] = useState(false);

    useEffect(() => {
        // If no text, reset
        if (!text) {
            setDisplayedText('');
            return;
        }

        // Clean and split the text
        const words = text.trim().replace(/\s+/g, ' ').split(' ');
        let currentIndex = 0;
        setIsAnimating(true);

        const animate = () => {
            // Calculate how many words to show
            const wordsToShow = Math.min(maxWords, words.length - currentIndex);
            const displayWords = words.slice(currentIndex, currentIndex + wordsToShow);

            // Update displayed text
            setDisplayedText(displayWords.join(' '));

            // Move to next set of words
            currentIndex += wordsToShow;

            // Schedule next update or stop animation
            if (currentIndex < words.length) {
                const timeoutId = setTimeout(animate, speed);
                return () => clearTimeout(timeoutId);
            } else {
                setIsAnimating(false);
            }
        };

        // Start animation
        const cleanup = animate();

        // Cleanup function to stop animation if component unmounts
        return () => {
            setIsAnimating(false);
            if (typeof cleanup === 'function') {
                cleanup();
            }
        };
    }, [text, speed, maxWords]);

    // If text is short or animation is complete, show full text
    if (!isAnimating) {
        return <>{text}</>;
    }

    return <>{displayedText}</>;
};

const ChatInterface = ({ agentData }) => {
    const videoRef = useRef(null);
    const audioRef = useRef(null);
    const [messages, setMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState("");
    const [pdffileUrl, setpdfFileUrl] = useState(null);
    const [pdfFile, setPdfFile] = useState(null);
    const [videoUrl, setVideoUrl] = useState(

    );
    const [loading, setLoading] = useState(true);
    const [requestId, setRequestId] = useState(null)
    const [audioUrl, setAudioUrl] = useState(null);
    const messagesEndRef = useRef(null);
    const [userImage, setUserImage] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileUrl, setFileUrl] = useState(null);
    const connectionRef = useRef(null);
    const [displayedText, setDisplayedText] = useState('');

    const [connectionStatus, setConnectionStatus] = useState("Disconnected");
    const [messagesauto, setMessagesauto] = useState([]);
    const [codeContent, setCodeContent] = useState(null);
    const wsUri = "ws://aws_rasa.hertzai.com:8088/ws";

    const TeacherId = 1980
    console.log(agentData);

    useEffect(() => {
        if (agentData) {
            const idleFiller = agentData.fillers?.find(filler => filler.type === 'idle');
            const defaultVideoUrl = idleFiller?.video_link || agentData.description;

            setVideoUrl(defaultVideoUrl);
        }
    }, [agentData]);





    useEffect(() => {
        const handleMediaEnd = () => {
            try {
                const idleVideo = agentData?.fillers?.find(filler => filler.type === "idle");

                if (idleVideo?.video_link) {
                    setVideoUrl(idleVideo.video_link);

                    if (videoRef.current) {
                        videoRef.current.src = idleVideo.video_link;
                        videoRef.current.load();
                        videoRef.current.play();
                    }
                } else {
                    console.warn("Idle video not found in agentData.fillers.");
                }
            } catch (error) {
                console.error("Error handling media end:", error);
            }
        };

        const handleAudioEnd = () => {
            handleMediaEnd();
        };

        // Add event listeners for video and audio end events
        const currentVideoRef = videoRef.current;
        const currentAudioRef = audioRef.current;

        if (currentVideoRef) {
            currentVideoRef.addEventListener('ended', handleMediaEnd);
        }
        if (currentAudioRef) {
            currentAudioRef.addEventListener('ended', handleAudioEnd);
        }

        // Cleanup event listeners when the component unmounts
        return () => {
            if (currentVideoRef) {
                currentVideoRef.removeEventListener('ended', handleMediaEnd);
            }
            if (currentAudioRef) {
                currentAudioRef.removeEventListener('ended', handleAudioEnd);
            }
        };
    }, [agentData, videoUrl, audioUrl]);



    useEffect(() => {
        const connection = new autobahn.Connection({
            url: wsUri,
            realm: "realm1",
        });

        connection.onopen = (session) => {
            setConnectionStatus("Connected");

            // Subscribe to various topics
            const topics = [
                `com.hertzai.pupit.${agentData.user_id}`,
                `com.hertzai.hevolve.analogy.${agentData.user_id}`,
                `com.hertzai.bookparsing.${agentData.user_id}`
            ];

            topics.forEach(topic => {
                session.subscribe(topic, (args) => {
                    try {
                        const parsedArgs = JSON.parse(args);
                        const videoLink = parsedArgs[0]?.video_link?.video;

                        if (videoLink) {
                            setVideoUrl(videoLink);
                        }

                        setMessagesauto((prev) => [...prev, parsedArgs]);
                    } catch (error) {
                        console.error(`Error parsing WebSocket message for ${topic}:`, error);
                    }
                });
            });
        };

        connection.onclose = (reason, details) => {
            setConnectionStatus("Disconnected");
        };

        connection.open();

        // Default video setup
        if (agentData) {
            const idleFiller = agentData.fillers?.find(filler => filler.type === 'idle');
            const defaultVideoUrl = idleFiller?.video_link || agentData.description;
            setVideoUrl(defaultVideoUrl);
        }

        return () => {
            connection.close();
        };
    }, [agentData, agentData.user_id]);






    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };




    useEffect(() => {
        scrollToBottom();
    }, [messages, loading]);

    const handlePdfSelect = async (event) => {
        const file = event.target.files[0];

        if (!file) {
            console.error("No file selected.");
            return;
        }

        try {
            // Set the selected PDF file
            setPdfFile(file);


            var formdata = new FormData();
            formdata.append("bot_type", "book_parsing");
            formdata.append("user_id", agentData.user_id);
            formdata.append("request_id", uuidv4());
            formdata.append("file", file, file.name);

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch("https://azurekong.hertzai.com:8443/book_parsing/book_parsing_upload_api", requestOptions)
                .then(async (response) => {
                    if (!response.ok) {
                        console.error("Failed to upload PDF:", response.status, response.statusText);
                        return;
                    }

                    // Parse the response as JSON
                    const result = await response.json();
                    setRequestId(result.request_id)

                    // Set the file URL from the response
                    setpdfFileUrl(result.file_url);
                })
                .catch(error => {
                    console.error('Error during PDF upload process:', error);
                });




            // // Prepare the payload

            if (connectionRef.current?.session) {
                const topicName = `com.hertzai.bookparsing.${agentData.user_id}`;
                connectionRef.current.session.subscribe(topicName, (args) => {

                    try {
                        const parsedArgs = JSON.parse(args);
                        const videoUrl = parsedArgs[0]?.video_link?.video;

                        if (videoUrl) {
                            setVideoUrl(videoUrl);
                        } else {
                            console.warn("Video URL not found in the response.");
                        }
                    } catch (error) {
                        console.error("Error parsing WebSocket message:", error);
                    }
                });
            } else {
                console.warn("WebSocket session is not available.");
            }
        } catch (error) {
            console.error("Error during PDF upload process:", error);
        }
    };




    const handleRemovePdf = () => {
        setPdfFile(null);
        setpdfFileUrl(null);
    };
    const handleImageSelect = async (event) => {
        const file = event.target.files[0];


        if (file) {
            setUserImage(URL.createObjectURL(file));
            setSelectedFile(file);

            const formData = new FormData();
            formData.append("user_id", agentData.user_id);
            formData.append("file", file, file.name);
            formData.append("request_id", uuidv4());

            try {
                const response = await fetch(
                    "https://azurekong.hertzai.com:8443/makeit/upload_file",
                    {
                        method: "POST",
                        body: formData,
                        redirect: "follow",
                    }
                );

                if (response.ok) {
                    const result = await response.json();

                    setFileUrl(result.file_url);


                } else {
                    console.error(
                        "Failed to upload image:",
                        response.status,
                        response.statusText
                    );
                }
            } catch (error) {
                console.error("Error uploading image:", error);
            }
        }
    };






    const handleRemoveImage = () => {
        setUserImage(null);
        setSelectedFile(null);
        setFileUrl(null);
    };




    const handleSend = async () => {
        if (!inputMessage.trim() && !fileUrl) return;

        const userMessage = { type: "user", content: inputMessage, image: fileUrl || null };
        setMessages((prevMessages) => [...prevMessages, userMessage]);
        setLoading(true);

        const dataToSend = JSON.stringify({
            text: inputMessage,
            user_id: 10077,
            teacher_avatar_id: agentData.teacher_avatar_id,
            conversation_id: uuidv4(),
            request_id: requestId
        });

        setInputMessage("");
        setFileUrl(null);

        try {
            const resp = await axios.post(configData.chatBotApi, dataToSend);

            if (resp.status === 200) {
                const dataJson = resp.data;
                console.log("Response Data:", resp.data);

                const botMessage = {
                    type: "assistant",
                    content: dataJson.text || '',
                    videoUrl: dataJson.videoUrl || videoUrl,
                };

                setMessages((prevMessages) => [...prevMessages, botMessage]);
                setVideoUrl(dataJson.video_link?.video);
                setAudioUrl(dataJson.video_link?.aud_url);
            } else {
                console.error("Server Error:", resp.status);
            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }

        setUserImage(null);
        setPdfFile(null)
        setpdfFileUrl(null)
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            handleSend();
        }
    };

    return (
        <div className="flex flex-col h-screen bg-black ">
            <div className=" h-5/6 flex overflow-hidden">
                {/* Left Side: Messages */}
                <div className="flex-1 overflow-y-auto">
                    {messages.length === 0 ? (
                        <div className="h-5/6 flex items-center justify-center">
                            <div className="text-center space-y-4">
                                <h2 className="text-2xl font-medium text-white-900">
                                    How can I help you today?
                                </h2>
                                <p className="text-white-600">
                                    I'm an AI assistant. I'm here to help with various topics!
                                </p>
                            </div>
                        </div>
                    ) : (
                        <div className="max-w-5xl mx-auto p-4 space-y-6">
                            {messages.map((message, index) => (
                                <div
                                    key={index}
                                    className="rounded-lg p-6 shadow-sm"
                                    style={{
                                        backgroundColor: message.type === "user" ? "#EFEAAA" : "#212A31",
                                        color: "#FFFFFF"
                                    }}
                                >
                                    <div className="flex-1 space-y-4">
                                        <p
                                            className={
                                                message.type === "user"
                                                    ? "text-black font-bold"
                                                    : "text-white"
                                            }
                                        > {message.content}
                                            {message.type === "assistant" && (
                                                <TypewriterText
                                                    text={message.content}
                                                    speed={2000}
                                                    maxWords={5}
                                                />
                                            )}
                                        </p>


                                        {message.code && (
                                            <button
                                                onClick={() => setCodeContent(message.code)}
                                                className="bg-blue-500 text-white px-3 py-1 rounded-md"
                                            >
                                                Show Code
                                            </button>
                                        )}
                                    </div>
                                </div>
                            ))}


                            {loading && (
                                <div className="flex items-center justify-start space-x-3">
                                    <span className="w-2 h-2 bg-gray-500 rounded-full animate-pulse"></span>
                                    <span className="w-2 h-2 bg-gray-500 rounded-full animate-pulse delay-200"></span>
                                    <span className="w-2 h-2 bg-gray-500 rounded-full animate-pulse delay-400"></span>
                                </div>
                            )}



                            <div ref={messagesEndRef} />
                        </div>
                    )}
                </div>


                <div className="w-1/3 bg-black p-4">

                    {videoUrl ? (
                        <div className="relative">
                            <video
                                src={videoUrl}
                                className="w-2/4 h-auto rounded-lg shadow-md m-auto"
                                autoPlay
                                ref={videoRef}

                                controlsList="nodownload noplaybackrate"
                                controls={false}
                            />
                        </div>
                    ) : audioUrl ? (
                        // Audio Player
                        <div>
                            <div className="flex items-center space-x-4 justify-center mt-5 px-4 py-4">
                                <div className="w-16 h-16 rounded-full bg-gray-200 flex items-center justify-center">
                                    <button className="text-gray-700 text-xl">

                                        ▶️
                                    </button>
                                </div>

                            </div>
                            <div className="mt-4">
                                <audio
                                    ref={audioRef}
                                    src={audioUrl}
                                    className="w-full"
                                    autoPlay
                                    controlsList="nodownload noplaybackrate"
                                    controls={false}
                                />

                            </div>
                        </div>
                    ) : null}





                    {/* Code Viewer */}
                    {codeContent && (
                        <div className="mt-4 bg-black p-4 rounded-lg shadow-md">
                            <h3 className="text-lg font-medium text-gray-900 mb-2">
                                Code Snippet
                            </h3>
                            <pre className="bg-gray-100 p-2 rounded-md text-sm overflow-auto">
                                <code>{codeContent}</code>
                            </pre>
                            <button
                                onClick={() => setCodeContent(null)}
                                className="mt-2 bg-red-500 text-white px-3 py-1 rounded-md"
                            >
                                Close Code
                            </button>
                        </div>
                    )}
                </div>
            </div>

            <div className="border-t border-gray-200 bg-black p-4">
                <div className="max-w-5xl mx-auto">
                    <div className="relative w-full">
                        <div className="flex items-center justify-center">
                            <textarea
                                value={inputMessage}
                                onChange={(e) => setInputMessage(e.target.value)}
                                placeholder="Message..."
                                className="text-black w-full border border-gray-200 rounded-lg pl-4 pr-16 py-3"
                                style={{ minHeight: "44px", maxHeight: "200px" }}
                                onKeyPress={handleKeyPress}
                            />
                            <button
                                onClick={() => document.getElementById("fileInput").click()}
                                className="text-gray-400 hover:text-gray-600 p-1"
                            >
                                <Image className="w-5 h-5" />
                            </button>


                            <button
                                onClick={() => document.getElementById("pdfInput").click()}
                                className="text-gray-400 hover:text-gray-600 p-1"
                            >
                                <FileText className="w-5 h-5" />
                            </button>

                            <button
                                onClick={handleSend}
                                className="text-gray-400 hover:text-gray-600 p-1"
                            >
                                <SendHorizontal className="w-5 h-5" />
                            </button>
                        </div>


                        <div className="absolute right-4 top-1/2 -translate-y-1/2 flex items-center space-x-2">

                        </div>


                        {pdfFile && (
                            <div className="relative inline-block mt-4">
                                <span className="text-gray-700">Uploaded PDF: {pdfFile.name}</span>
                                <button
                                    onClick={handleRemovePdf}
                                    className="text-red-600 hover:text-red-800 p-1 ml-2"
                                >
                                    Remove
                                </button>
                            </div>
                        )}

                        {userImage && (
                            <div className="relative inline-block mt-4">
                                <span className="text-gray-700">Uploaded Image:</span>
                                <img
                                    src={userImage}
                                    alt="Uploaded by User"
                                    className="w-16 h-16 object-cover rounded-lg shadow-md mt-2"
                                />
                                <button
                                    onClick={handleRemoveImage}
                                    className="text-red-600 hover:text-red-800 p-1 ml-2"
                                >
                                    Remove
                                </button>
                            </div>
                        )}

                        <input
                            type="file"
                            id="fileInput"
                            accept="image/*"
                            onChange={handleImageSelect}
                            className="hidden"
                        />
                        <input
                            type="file"
                            id="pdfInput"
                            accept="application/pdf"
                            onChange={handlePdfSelect}
                            className="hidden"
                        />
                    </div>
                </div>
            </div>
        </div >
    );
};

export default ChatInterface;