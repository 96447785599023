import React, { useEffect } from 'react'

import './assets/css/tailwind.css'
import './assets/css/materialdesignicons.min.css'

import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';

import MainRoutes from './MainRoute';



function App() {
  ReactGA.initialize('G-E527GVJ64Y');
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return (

    <>
      <MainRoutes />
    </>
  );
}

export default App;
